import { runInAction } from "mobx";
import AppStore from "./AppStore";
import Store from "./Store";
import LicenseModel from "../model/LicenseModel";

export default class LicenseStore extends Store<any, LicenseModel> {
  items = new Map<number, LicenseModel>();
  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: any[] = []) {
    runInAction(() => {
      items.forEach((item, index) =>
        this.items.set(index, new LicenseModel(this.store, item))
      );
    });
  }
}
