import { useAppContext } from "../../components/context/Context"
import ErrorBoundary from "../../components/error/ErrorBoundary"
import { observer } from "mobx-react-lite"
import { IQuery } from "../../components/model/QueryModel";
import EmptyError from "../../components/error/EmptyError";
import { useNavigate } from "react-router-dom";
interface IProps {
  query: IQuery;
}
const QueryItem = (props: IProps) => {
  const { store } = useAppContext();
  const navigate = useNavigate()
  const queryId = window.localStorage.getItem("queryId");
  const { query } = props;

  const onQueryClick = () => {
    store.query.setQueryId(query.id);
    window.localStorage.setItem("queryId", `${query.id}`)
    navigate(`/user/licenses/${query.id}`);
  };

  return (
    <div onClick={onQueryClick} className={`query ${Number(queryId) === query.id ? "active" : ""} uk-card uk-card-body uk-card-small uk-card-default`}>
      <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">Name</p>
      <h6 className="uk-margin-remove">{query.name} </h6>
      <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">Description</p>
      <p className="uk-margin-remove"> {query.description}</p>
    </div>
  );
};

const Queries = observer(() => {
  const { store } = useAppContext();

  return (
    <div className="queries uk-section">
      <div className="queries-card uk-card uk-card-default uk-card-body uk-border-rounded">
        <ErrorBoundary>
          {store.query.all.length > 0 ? (
            <ErrorBoundary>
              <div className="uk-child-width-1-3@s uk-child-width-1-6@m uk-grid-match uk-grid-small uk-margin" data-uk-grid>
                {store.query.all.map((query) => (
                  <div key={query.asJson.id}>
                    <QueryItem query={query.asJson} />
                  </div>
                ))}
              </div>
            </ErrorBoundary>
          ) : (
            <div className="uk-text-center uk-margin-top">
              <EmptyError errorMessage="No data" />
            </div>
          )}
        </ErrorBoundary>
      </div>
    </div>
  )
})
export default Queries