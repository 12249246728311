var mutationObserver: MutationObserver;

const GridScrollBar = () => {
    const headerSelector = '.ag-header';
    const scrollSelector = '.ag-body-horizontal-scroll';
    const scrollViewportSelector = '.ag-body-horizontal-scroll-viewport';
    const scrollContainerSelector = '.ag-body-horizontal-scroll-container';

    // get scrollbar elements
    const scrollElement = document.querySelector(scrollSelector) as Element;
    const scrollViewportElement = document.querySelector(scrollViewportSelector) as Element;
    const scrollContainerElement = document.querySelector(scrollContainerSelector) as Element;

    // create scrollbar clones
    const cloneElement = scrollElement.cloneNode(true) as Element;
    const cloneViewportElement = cloneElement.querySelector(scrollViewportSelector) as Element;
    const cloneContainerElement = cloneElement.querySelector(scrollContainerSelector) as Element;

    // insert scrollbar clone
    const headerElement = document.querySelector(headerSelector) as Element;
    headerElement.insertAdjacentElement('afterend', cloneElement);

    // add event listeners to keep scroll position synchronized
    scrollViewportElement.addEventListener('scroll', () => cloneViewportElement.scrollTo({ left: scrollViewportElement.scrollLeft }));
    cloneViewportElement.addEventListener('scroll', () => scrollViewportElement.scrollTo({ left: cloneViewportElement.scrollLeft }));

    // create a mutation observer to keep scroll size synchronized
    mutationObserver = new MutationObserver(mutationList => {
        for (const mutation of mutationList) {
            switch (mutation.target) {
                case scrollElement:
                    cloneElement.setAttribute('style', scrollElement.getAttribute('style') as string);
                    break;
                case scrollViewportElement:
                    cloneViewportElement.setAttribute('style', scrollViewportElement.getAttribute('style') as string);
                    break;
                case scrollContainerElement:
                    cloneContainerElement.setAttribute('style', scrollContainerElement.getAttribute('style') as string);
                    break;
            }
        }
    });
    mutationObserver.observe(scrollElement, { attributeFilter: ['style'], subtree: true });// start observing 
}

export default GridScrollBar