import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { GridState } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import { useAppContext } from '../../components/context/Context';
import ErrorBoundary from '../../components/error/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import showModalFromId from '../../components/modal/ModalShow';
import { MODAL_NAMES } from '../../components/model/Constants';
import SingleSelect, { IOption } from '../../components/select/SingleSelect';
import Modal from '../../components/modal/Modal';
import { LoadingEllipsis } from '../../components/loading/Loading';
import EmptyError from '../../components/error/EmptyError';
import SaveFilterModal from '../../components/dialog/SaveFilterModal';
import ApplyGridState from './ApplyGridState';
import { getParams } from './utils';
import ExtractColumnsFromData from './ExtractColumnsFromData';
import TableComponent from './TableComponent';

// import localData from "../../junk/data/data.json"

const Licensing = observer(() => {

  const { api, store } = useAppContext();

  const gridRef = useRef<AgGridReact>(null);
  const [fetchingData, setFetchingData] = useState(true);
  let refresh: boolean = false;

  const [rowData, setRowData] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const _columnDefs = ExtractColumnsFromData(columnDefs);

  const queryId = store.query.queryId;
  const { t: msg } = useTranslation(["home"])
  const [initialState, setInitialState] = useState<GridState>({})
  const [active, setActive] = useState<number | null>(null);

  const saveGridState = () => {
    if (gridRef.current) {
      const gridState = gridRef.current.api.getState();
      setInitialState(gridState)
      showModalFromId(MODAL_NAMES.USER.SAVE_FILTER_MODAL);
    };
  }

  // const onStateUpdated = useCallback((params: StateUpdatedEvent<any>) => {
  //   console.log('State updated', params.state);
  //   const _rowData = gridRef.current?.api.getRenderedNodes().flatMap((item) => item.data);
  //   if (_rowData) {
  //     console.log(_rowData);
  //     // setRowData(_rowData)
  //   }

  // }, []);
  // gridRef.current?.api.getRenderedNodes()
  // const handleApplyState = (value: number | null) => {
  //   if (gridRef.current) {
  //     gridRef.current.api.showLoadingOverlay();
  //     resetGridState();
  //     const filter = store.filter.all.find((item) => item.asJson.id === Number(value));
  //     const columns: GridState = filter ? filter.asJson.columns : {};
  //     setActive(value);
  //     ApplyGridState(columns, gridRef);
  //     gridRef.current.api.hideOverlay();
  //   }
  // };

  const handleApplyState = (value: number | null) => {
    if (gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
      resetGridState();
      const filter = store.filter.all.find((item) => item.asJson.id === Number(value));
      const columns: GridState = filter ? filter.asJson.columns : {};
      setActive(value);

      setTimeout(() => {
        ApplyGridState(columns, gridRef);
        gridRef.current?.api.hideOverlay();
      }, 2000);
    }
  };

  const resetGridState = () => {
    if (gridRef.current) {
      const apiRef = gridRef.current.api;
      setActive(null)
      apiRef.refreshClientSideRowModel();
      apiRef.clearRangeSelection();
      apiRef.setSideBarVisible(true)
      apiRef.closeToolPanel()
      apiRef.resetColumnGroupState();
      apiRef.resetColumnState();
      apiRef.setGridOption('pivotMode', false);
      apiRef.setPivotColumns([]);
      apiRef.clearFocusedCell()
      apiRef.setFilterModel(null)
    }
  }

  const handleDeleteFilter = async (id: number) => {
    await api.filter.delete(id);
    store.filter.remove(id);
  }

  const onExportExcel = useCallback(() => {
    gridRef.current && gridRef.current.api.exportDataAsExcel(getParams())
  }, [])

  const onLoadFreshData = async () => {
    if (gridRef.current && gridRef.current.api) {
      refresh = true;
      gridRef.current.api.showLoadingOverlay();
      const _data = await api.license.getAll(queryId, refresh)
      if (_data) {
        setRowData(_data.content || []);
        setTotalElements(_data.totalElements);
      }
      gridRef.current.api.hideOverlay();
    }
  }

  const filterOptions: IOption[] = useMemo(() =>
    store.filter.all.sort((a, b) => a.asJson.name.localeCompare(b.asJson.name)).map((item) => {
      return { label: item.asJson.name || "", value: item.asJson.id }
    }), [store.filter.all])

  const loadData = useCallback(async () => {
    setFetchingData(true);

    const _data = await api.license.getAll(queryId, refresh);
    if (_data) {
      setRowData(_data.content || []);
      setTotalElements(_data.totalElements);
    }
    // setRowData(localData as any[]);
    const _columns = await api.documentation.getDocumentation(queryId)
    setColumnDefs(_columns || []);

    await api.filter.getAll();

    setFetchingData(false);
  }, [api.license, api.documentation, api.filter, queryId, refresh]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line 
  }, []);

  return (
    <div className="uk-section licenses">
      <div className="licences-card uk-card uk-card-default uk-card-body uk-border-rounded">
        <div className="uk-flex uk-flex-between" data-uk-grid>
          <div className="uk-inline uk-flex">
            <div>
              <button className="uk-button btn-primary uk-border-rounded uk-margin-bottom" onClick={onExportExcel}>
                {msg("exportButton")}
              </button>
            </div>
            <div>
              <button className="uk-margin-left uk-button btn-primary uk-border-rounded"
                onClick={onLoadFreshData} data-uk-tooltip={msg("reloadTooltip")}>
                {msg("reloadButton")}
              </button>
            </div>
          </div>
          <div className="uk-inline uk-flex">
            <div className="uk-flex uk-flex-between" data-uk-grid>
              <div>
                <button className="uk-margin-right uk-button btn-primary select-filter-button" type="button">
                  {msg("selectFilter")}
                </button>
              </div>
              <div data-uk-dropdown="pos: bottom-center" className="filter-dropdown">
                <ul className="uk-nav uk-dropdown-nav">
                  {store.filter.all.sort((a, b) => a.asJson.name.localeCompare(b.asJson.name)).map((item) => (
                    <li className="uk-margin" key={item.asJson.id}>
                      <button
                        data-uk-tooltip={item.asJson.description}
                        className={`uk-button uk-button-small filter-text-button ${active === item.asJson.id ? "btn-primary" : "uk-button-default"}`}
                        type="button"
                        onClick={() => handleApplyState(item.asJson.id)}>
                        <span> {item.asJson.name.substring(0, 35)}...</span>
                      </button>
                      <button
                        className="filter-delete-button"
                        type="button"
                        data-uk-tooltip="Delete"
                        onClick={() => handleDeleteFilter(item.asJson.id)}>
                        <div className='icon' data-uk-icon="icon: close; ratio: .8"></div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <SingleSelect msg={msg} name="search-name" options={filterOptions} onChange={handleApplyState} />
              </div>
              <div>
                <button onClick={resetGridState} className=" uk-button btn-primary uk-border-rounded">
                  {msg("resetFilter")}
                </button>
              </div>
            </div>
          </div>
          <div className='uk-flex'>
            <div>
              <button onClick={saveGridState} className="uk-button btn-primary uk-border-rounded uk-margin-small-right">
                {msg("saveFilter")}
              </button>
            </div>
          </div>
        </div>
        <ErrorBoundary>
          {fetchingData ? (
            <LoadingEllipsis />
          ) : rowData && rowData.length > 0 ? (
            <ErrorBoundary>
              <div><small><b>{msg("totalData")}: {totalElements}</b></small></div>
              <TableComponent
                gridRef={gridRef}
                columnDefs={_columnDefs}
                rowData={rowData}
                initialState={initialState}
                setInitialState={setInitialState}
                // onStateUpdated={onStateUpdated}
              />
            </ErrorBoundary>
          ) : (
            <EmptyError errorMessage="No data" />
          )}
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Modal modalId={MODAL_NAMES.USER.SAVE_FILTER_MODAL}>
          <SaveFilterModal initialState={initialState} />
        </Modal>
      </ErrorBoundary>
    </div>
  );
});

export default Licensing;