import { Outlet } from "react-router-dom";
import NavBar from "../../components/nav/NavBar";
import { LoadingEllipsis } from "../../components/loading/Loading";
interface IProps {
  loading: boolean;
}
const Layout = (props: IProps) => {
  const { loading } = props;

  return (
    <main className="layout">
      <NavBar />
      {!loading && <Outlet />}
      {loading && <LoadingEllipsis fullHeight />}
    </main>
  );
};

export default Layout;