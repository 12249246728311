import AppStore from "../store/AppStore"
import AppApi from "./AppApi"
export class DocumentationApi {
  constructor(private api: AppApi, private store: AppStore) { }

  private apiUrl = `${process.env.REACT_APP_API_ENDPOINT}`

  async getDocumentation(queryId: number) {
    try {
      const res = await fetch(`${this.apiUrl}/rs2/controlling/documentation?queryId=${queryId}`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })
      if (res.ok) {
        const data = await res.json();
        return data
      }
    } catch (error) { }
  }
}