import { User } from "oidc-client-ts";
import { IUser } from "../model/User"
import AppStore from "../store/AppStore"
import AppApi from "./AppApi"
import { useAuth } from "react-oidc-context";
export default class AuthApi {

  constructor(private api: AppApi, private store: AppStore,) {
    this.handleAuth();
  }

  private auth = useAuth();

  private handleAuth() {
    try {
      this.createHeaders()
      this.loadDetail()
    } catch (error) {
    }
  }

  createHeaders(): Headers {
    const _headers = new Headers();
    _headers.append('Accept', 'application/json');
    _headers.append("Content-Type", "application/json")
    _headers.append('Authorization', `Bearer ${this.auth.user?.access_token}`);
    return _headers;
  };

  async loadDetail() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENTID}`);
    if (!oidcStorage) {
      return null;
    }
    const data = User.fromStorageString(oidcStorage);
    const _data: IUser = {
      uid: data.profile.sub,
      displayName: data.profile.name,
      email: data.profile.email
    }
    this.store.user.load(_data);
  }

  async signout() {
    this.auth.removeUser();
  }

  async login() {
    this.auth.signinRedirect();
  }

}