import { useTranslation } from "react-i18next";
import ErrorBoundary from "../components/error/ErrorBoundary";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { observer } from "mobx-react-lite";

const Login = observer(() => {

    const auth = useAuth()
    const { t: msg, i18n } = useTranslation(['home']);
    const lang = navigator.language

    async function onLogin() {
        await auth.signinRedirect();
    };

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [i18n, lang])

    return (
        <ErrorBoundary>
            <div className="login" data-uk-height-viewport>
                <nav className="logged-out-navbar" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <img className="logged-out-logo" alt="APST" src={process.env.PUBLIC_URL + `/images/apst.png`} />
                    </div>
                </nav>
                <div className="uk-section uk-flex uk-flex-middle uk-animation-fade uk-padding-remove-top">
                    <div className="uk-width-1-1">
                        <div className="uk-container">
                            <div className="uk-grid-margin uk-grid uk-grid-stack" data-uk-grid>
                                <div className="uk-width-1-1@m">
                                    <div className="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-border-rounded login-card">
                                        <h3 className="uk-card-title uk-text-center"> {msg("portal")}</h3>
                                        <div className="uk-margin-medium-top uk-text-center">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={onLogin}>
                                                {msg("login")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )
})
export default Login