
import FilterStore from "./FilterStore";
import LicenseStore from "./LicenseStore";
import QueryStore from "./QueryStore";
import UserStore from "./UserStore";
export default class AppStore {
  user = new UserStore(this);
  filter = new FilterStore(this);
  query = new QueryStore(this);
  license = new LicenseStore(this);
}
