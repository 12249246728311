const lang = navigator.language

export function dateFormat(dateString: string) {
    return new Date(dateString).toLocaleDateString(lang);
};

export function currencyFormatter(money: number) {

    if (!money) {
        return '';
    }
    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR'
    }).format(money);
};

export function getParams() {
    return {
        fileName: `Licences_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`,
        sheetName: "Licensing",
    }
}