const dateComparator = (filterLocalDateAtMidnight: string | number | Date, cellValue: string) => {
    const dateParts = cellValue.split('-');
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2].split('T')[0]);

    const cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    } else {
        return 0;
    }
};


const DateFilterParams = {
    comparator: dateComparator,
    browserDatePicker: true,
};

export default DateFilterParams