import { makeAutoObservable, toJS } from "mobx"
import AppStore from "../store/AppStore"

export interface IFilter {
  id: number
  name: string
  description: string
  columns: {}
  version: 0
}

export const defaultFilter: IFilter = {
  id: 0,
  name: "",
  description: "",
  columns: {},
  version: 0,
}

export default class CustomFilterModel {
  private filter: IFilter

  constructor(private store: AppStore, filter: IFilter) {
    makeAutoObservable(this)
    this.filter = filter
  }

  get asJson(): IFilter {
    return toJS(this.filter)
  }
}
