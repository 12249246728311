import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import { IUser, UserModel } from "../model/User";
export default class UserStore {
  protected store: AppStore;
  isUser: UserModel | null = null;
  loading: boolean = true;
  userError: boolean = false;

  constructor(store: AppStore) {
    makeObservable(this, {
      isUser: true,
      loading: true,
      credential: true,
      userError: false
    });
    this.store = store;
  }

  get credential() {
    return this.isUser ? this.isUser.asJson : null;
  }

  load(item: IUser) {
    runInAction(() => {
      this.isUser = new UserModel(this.store, item);
    });
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.loading = loading;
    });
  }

  setUserError(userError: boolean) {
    runInAction(() => {
      this.loading = userError;
    });
  }
}
