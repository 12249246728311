import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppContext } from './components/context/Context';
import Login from './loggedout/Login';
import { LoadingEllipsis } from './components/loading/Loading';
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import ErrorBoundary from "./components/error/ErrorBoundary";
import AppApi from "./components/api/AppApi";
import AppStore from "./components/store/AppStore";
import Queries from "./loggedin/queries/Queries";
import Licensing from "./loggedin/licensing/Licensing";
import LoggedIn from "./loggedin/LoggedIn";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
interface IProps {
  children: JSX.Element;
}

const PrivateRoute = (props: IProps) => {
  const { children } = props;
  return children
};

const AuthRedirect = () => <Navigate to="/user/queries" />;

const PrivateRouteWithAuth = withAuthenticationRequired(PrivateRoute, {
  OnRedirecting: () => <AuthRedirect />
});

const PrivateLoggedIn = () => {
  return (
    <PrivateRouteWithAuth>
      <LoggedIn />
    </PrivateRouteWithAuth>
  )
};

const AppRoutes = observer(() => {
  const auth = useAuth();

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(async () => {
      await auth.signinSilent()
    })
  }, [auth.events, auth.signinSilent, auth]);

  if (auth.isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/user/queries" />} />
          <Route path="*" element={<Navigate to="/user/queries" />} />
          <Route path="/user" element={<PrivateLoggedIn />} >
            <Route path={"dashboard"} element={<>Dashboard</>} />
            <Route path="queries" element={<Queries />} />
            <Route path={"licenses/:queryId"} element={<Licensing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  if (auth.isLoading) {
    return (<div><LoadingEllipsis fullHeight /></div>)
  }

  if (auth.error) {
    return (
      <ErrorBoundary>
        <div className="uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade" data-uk-height-viewport>
          <div className="uk-container">
            <div className="uk-border-rounded uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body btn-primary">
              <p className="uk-text-center">An error occured! Please try again later.</p>
              <h3 className="uk-text-center uk-text-warning">Ok ? </h3>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
})

const App = () => {

  const store = new AppStore();
  const api = new AppApi(store);

  return (
    <AppContext.Provider value={{ store, api }}>
      <AppRoutes />
      <ToastContainer />
    </AppContext.Provider>
  )
};
export default App;