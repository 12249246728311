import AppStore from "../store/AppStore";
import AuthApi from "./AuthApi";
import { DocumentationApi } from "./DocumentationApi";
import { FiltersApi } from "./FiltersApi";
import { LicenceApi } from "./LicenceApi";
import { QueryApi } from "./QueryApi";
export default class AppApi {
  auth: AuthApi;
  license: LicenceApi;
  filter: FiltersApi;
  query: QueryApi;
  documentation: DocumentationApi;

  constructor(store: AppStore) {
    this.auth = new AuthApi(this, store);
    this.license = new LicenceApi(this, store);
    this.filter = new FiltersApi(this, store);
    this.query = new QueryApi(this, store);
    this.documentation = new DocumentationApi(this, store)
  }
}
