import { useCallback, useMemo, memo, SetStateAction, Dispatch, RefObject } from 'react';
import { ColDef, SideBarDef, GridState } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import LOCALE_DE from "../../components/locale/deutsch.json"
import LOCALE_EN from "../../components/locale/english.json"
import CustomLoadingOverlay from "../../components/loading/CustomLoadingOverlay";
import GridScrollBar from './GridScrollBar';

var mutationObserver: MutationObserver;

interface IProps {
    columnDefs: ColDef<any, any>[];
    rowData: any[];
    initialState: GridState
    setInitialState: Dispatch<SetStateAction<GridState>>
    gridRef: RefObject<AgGridReact<any>>
    // onStateUpdated: (params: StateUpdatedEvent<any>) => void
}

const TableComponent = memo((props: IProps) => {

    const { columnDefs, rowData, initialState, gridRef } = props;

    const _theme = window.localStorage.getItem("theme") as string;
    const gridStyle = _theme === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz";
    const lang = navigator.language
    const localeText = useMemo(() => { return lang === "de" ? LOCALE_DE : LOCALE_EN }, [lang]);
    const paginationPageSizeSelector = useMemo(() => { return [10, 20, 30, 50, 100, 150, 200, rowData.length] }, [rowData.length]);
    const rowSelection = "multiple";
    const autoGroupColumnDef = useMemo<ColDef>(() => { return { filter: "agGroupColumnFilter" }; }, []);
    const loadingOverlayComponent = useMemo(() => { return CustomLoadingOverlay }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                }
            ],

            position: 'right',
            hiddenByDefault: true
        };
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            filter: true,
            enableRowGroup: true,
            cellDataType: false,
        };
    }, []);

    const onGridReady = () => {
        gridRef.current && gridRef.current.api.setSideBarVisible(true);
        GridScrollBar()
    }

    const onGridPreDestroyed = useCallback(() => {
        mutationObserver && mutationObserver.disconnect();
    }, []);

    return (
        <div className={gridStyle} >
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                pagination={true}

                loadingOverlayComponent={loadingOverlayComponent}
                reactiveCustomComponents={true}
                rowSelection={rowSelection}
                paginationPageSize={100}
                paginationPageSizeSelector={paginationPageSizeSelector}
                tooltipShowDelay={500}
                tooltipMouseTrack={true}
                localeText={localeText}
                rowGroupPanelShow={"always"}
                domLayout={"autoHeight"}
                sideBar={sideBar}
                onGridReady={onGridReady}
                enableRangeSelection={true}
                initialState={initialState}
                onGridPreDestroyed={onGridPreDestroyed}

                //
                // onStateUpdated={onStateUpdated}
                // paginationAutoPageSize={true}
                // groupSelectsChildren={true}
            />
        </div>
    );
});

export default TableComponent;