import AppStore from "../store/AppStore"
import AppApi from "./AppApi"
export class LicenceApi {
  constructor(private api: AppApi, private store: AppStore) { }

  private apiUrl = `${process.env.REACT_APP_API_ENDPOINT}`

  async getAll(queryId: number, refresh: boolean) {
    try {
      const res = await fetch(`${this.apiUrl}/rs2/controlling?queryId=${queryId}&refresh=${refresh}`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })

      if (res.ok) {
        const data = await res.json();
        const { content, totalElements } = data;
        return { content, totalElements };
      }
    } catch (error) { }
  }
}