import { GridState } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { RefObject } from "react";

function ApplyGridState(columns: GridState, gridRef: RefObject<AgGridReact<any>>) {
    if (gridRef.current) {
        const apiRef = gridRef.current.api;

        if (columns.aggregation) {
            const columnState = columns.aggregation.aggregationModel.map(aggregation => ({
                colId: aggregation.colId,
                aggFunc: aggregation.aggFunc
            }));

            apiRef.applyColumnState({ state: columnState, applyOrder: true });
        }

        if (columns.columnGroup) {
            const columnGroupState = columns.columnGroup.openColumnGroupIds.map(groupId => ({
                groupId,
                open: true,
            }));
            apiRef.setColumnGroupState(columnGroupState);
        }

        if (columns.columnOrder && columns.columnOrder.orderedColIds) {
            apiRef.applyColumnState({
                state: columns.columnOrder.orderedColIds.map(colId => ({
                    colId: colId,
                    order: columns.columnOrder && columns.columnOrder.orderedColIds.indexOf(colId)
                })),
                applyOrder: true
            });
        }

        if (columns.columnPinning) {
            if (columns.columnPinning.leftColIds) {
                apiRef.setColumnsPinned(columns.columnPinning.leftColIds, 'left');
            }
            if (columns.columnPinning.rightColIds) {
                apiRef.setColumnsPinned(columns.columnPinning.rightColIds, 'right');
            }
        }

        if (columns.columnSizing && columns.columnSizing.columnSizingModel) {
            const columnWidths = columns.columnSizing.columnSizingModel.map(col => ({
                key: col.colId,
                newWidth: Number(col.width)
            }));
            apiRef.setColumnWidths(columnWidths);
        }

        if (columns.columnVisibility && columns.columnVisibility.hiddenColIds) {
            apiRef.setColumnsVisible(columns.columnVisibility.hiddenColIds, false);
        }

        if (columns.filter && columns.filter.filterModel) {
            apiRef.setFilterModel(columns.filter.filterModel);
        }

        if (columns.focusedCell) {
            apiRef.setFocusedCell(columns.focusedCell.rowIndex, columns.focusedCell.colId, columns.focusedCell.rowPinned);
        }

        if (columns.pagination && columns.pagination.page) {
            apiRef.updateGridOptions({ paginationPageSize: columns.pagination.pageSize });
            apiRef.paginationGoToPage(columns.pagination.page);
        }

        if (columns.pivot) {
            apiRef.setGridOption('pivotMode', columns.pivot.pivotMode);
            apiRef.setPivotColumns(columns.pivot.pivotColIds)
        }

        if (columns.rangeSelection && columns.rangeSelection.cellRanges) {
            columns.rangeSelection.cellRanges.forEach(cellRange => {
                const cellRangeParams = {
                    rowStartIndex: cellRange.startRow!.rowIndex,
                    rowEndIndex: cellRange.endRow!.rowIndex,
                    startColumn: cellRange.startColId,
                    columns: cellRange.colIds
                };
                apiRef.addCellRange(cellRangeParams);
            });
        }

        if (columns.rowGroup && columns.rowGroup.groupColIds) {
            apiRef.setRowGroupColumns(columns.rowGroup.groupColIds);
        }

        if (columns.rowGroupExpansion && columns.rowGroupExpansion.expandedRowGroupIds) {
            apiRef.setRowGroupColumns(columns.rowGroupExpansion.expandedRowGroupIds);
        }

        if (columns.sideBar) {
            apiRef.setSideBarVisible(columns.sideBar.visible);
            apiRef.setSideBarPosition(columns.sideBar.position);
            if (columns.sideBar.openToolPanel) {
                apiRef.openToolPanel(columns.sideBar.openToolPanel);
            }
        }

        if (columns.sort && columns.sort.sortModel) {
            apiRef.applyColumnState({ state: columns.sort.sortModel });
        }
    }
};

export default ApplyGridState