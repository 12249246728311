import { useState, useCallback, useEffect } from "react";
import { useAppContext } from "../components/context/Context";
import Drawer from "../components/nav/Drawer";
import Layout from "./layout/Layout";
import { observer } from "mobx-react-lite";

const LoggedIn = observer(() => {

  const { api } = useAppContext();
  const [loading, setLoading] = useState(true);

  const loadQueries = useCallback(async () => {
    await api.query.getQuery();
    setLoading(false)
  }, [api.query])

  useEffect(() => {
    loadQueries();
  }, [loadQueries])

  return (
    <div className="loggedin">
      <Drawer />
      <Layout loading={loading} />
    </div>
  );
});

export default LoggedIn;