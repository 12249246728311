import { ColDef } from "@ag-grid-community/core";
import TicketNumberUrlRenderer from "./TicketNumberUrlRenderer";
import DateFilterParams from "./DateFilterParams";
import { currencyFormatter, dateFormat } from "./utils";

function ExtractColumnsFromData(columnDefs: any[]) {
    if (columnDefs.length === 0) return []
    const columns: ColDef[] = []

    for (const { displayColumnName, technicalColumnName, description, dataType } of columnDefs) {
        if (technicalColumnName !== "ticketNumberUrl") {
            const column: ColDef = {
                field: technicalColumnName,
                headerName: displayColumnName,
                headerTooltip: description,
            }
            if (dataType === "STRING") {
                column.autoHeight = true
                column.cellStyle = { whiteSpace: 'pre' }
            }
            if (String(displayColumnName).endsWith("[€]")) {
                column.aggFunc = 'sum'
                column.allowedAggFuncs = [`sum`, `min`, `max`, `count`, `avg`, `first`, `last`];
                column.valueFormatter = (params) => {
                    const value = params?.data?.[technicalColumnName];
                    return value !== undefined ? currencyFormatter(value) : '';
                };
                column.useValueFormatterForExport = false;
            }

            if (dataType === "DATE") {
                column.filter = "agDateColumnFilter"
                column.valueFormatter = (params) => {
                    const value = params?.data?.[technicalColumnName];
                    return value !== undefined ? dateFormat(value) : '';
                };
                column.filterParams = DateFilterParams
            }
            if (technicalColumnName === "ticketNumber") {
                column.cellRenderer = TicketNumberUrlRenderer;
            }
            columns.push(column)
        }
    }
    return columns
}

export default ExtractColumnsFromData