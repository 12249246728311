import { makeObservable, runInAction, toJS } from "mobx";
import AppStore from "./AppStore";
export default abstract class Store<Type, Model> {
  items = new Map<string | number, Model>();
  selected: Type | null = null;
  protected store: AppStore;

  constructor(store: AppStore) {
    this.store = store;
    makeObservable(this, {
      items: true,
      all: true,
    });
  }

  get all() {
    return Array.from(toJS(this.items.values()));
  }

  remove(id: string | number) {
    runInAction(() => {
      if (toJS(this.items.has(id))) this.items.delete(id);
    });
  }
}
