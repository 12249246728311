import { useAppContext } from "../context/Context"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const NavBar = () => {
  
  const { api, store } = useAppContext()
  const user = store.user.credential
  const lang = navigator.language
  const { t: msg, i18n } = useTranslation(["home"]);

  const dark = process.env.PUBLIC_URL + `/images/dark_mode.svg`;
  const light = process.env.PUBLIC_URL + `/images/light_mode.svg`;

  const body = document.body;
  const [theme, setTheme] = useState(":root");

  const toggleTheme = () => {
    const newTheme = theme === ":root" ? "dark" : ":root";
    setTheme(newTheme);
    body.classList.toggle(`${theme}`);
    body.classList.toggle(`${newTheme}`);
    window.localStorage.setItem("theme", newTheme);
  }

  const onLogout = () => {
    api.auth.signout();
  }

  useEffect(() => {
    const _theme = window.localStorage.getItem("theme") as string;
    setTheme(_theme);
    body.classList.toggle(`${_theme}`);

    i18n.changeLanguage(lang)
  }, [i18n, lang, body.classList])

  return (
    <div className="sticky" data-uk-sticky="sel-target: .uk-navbar; cls-active: uk-navbar-sticky">
      <nav className="navbar" data-uk-navbar>
        <div className="uk-navbar-left uk-hidden@s">
          <button className="uk-navbar-toggle" data-uk-navbar-toggle-icon data-uk-toggle="target: #navbar-drawer" />
        </div>
        <div className="navbar-right uk-navbar-right">
          <ul className="uk-navbar-nav">
            <li>
              <button className="theme" onClick={toggleTheme}>
                <img src={theme === ":root" ? dark : light} alt="" />
              </button>
            </li>
            <li>
              <button className={"locale active"}>{lang}</button>
            </li>
            <li className="username-li-item">
              <p className="username">
                <span className="name">{user?.displayName}</span>
              </p>
            </li>
            <li>
              <button className="uk-button btn-primary uk-border-rounded" onClick={() => onLogout()}>
                <span className="uk-margin-small-right" data-uk-icon="sign-out"></span>
                {msg("signOutButton")}
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
