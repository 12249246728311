import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import german from './deutsch.json';
import english from './english.json';

const resources = {
    en: {
        home: english,
    },
    de: {
        home: german,
    },
}

i18next.use(initReactI18next).init({
    supportedLngs: ['de', 'en'],
    resources,
    lng: 'de',
    debug: false,
    fallbackLng: 'de',
    saveMissing: true
});


export default i18next;
