import { observer } from "mobx-react-lite";
import { FormEvent, useState } from "react";
import { useAppContext } from "../context/Context";
import { hideModalFromId } from "../modal/ModalShow";
import { MODAL_NAMES } from "../model/Constants";
import { IFilter, defaultFilter } from "../model/CustomFilterModel";
import { toast } from "react-toastify";
interface IProps {
  initialState: {}
}

const SaveFilterModal = observer((props: IProps) => {

  const { initialState } = props
  const { api } = useAppContext();

  const [item, setItem] = useState<IFilter>({ ...defaultFilter });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const _filter: IFilter = {
        ...item,
        columns: initialState
      }
      await api.filter.save(_filter);
      toast.success(`Success!`, { autoClose: 500 });
    } catch (error) { }
    setLoading(false);
    onCancel();
  };

  const onCancel = () => {
    setItem({ ...defaultFilter });
    hideModalFromId(MODAL_NAMES.USER.SAVE_FILTER_MODAL);
  };

  return (
    <div className="uk-border-rounded uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button className="uk-modal-close-default" type="button" data-uk-close />
      <div className="dialog-content uk-position-relative">
        <form
          className="uk-form-stacked uk-grid-small"
          onSubmit={onSubmit}
          data-uk-grid>
          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="filterName">
              Name
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-medium"
                id="filterName"
                type="text"
                value={item.name}
                onChange={(e) =>
                  setItem({ ...item, name: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="description">
              Description
            </label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                id="description"
                value={item.description}
                rows={3}
                onChange={(e) =>
                  setItem({ ...item, description: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="uk-width-1-1 uk-text-right">
            <button
              className="btn-text uk-margin-right"
              type="button"
              onClick={onCancel}>
              Cancel
            </button>
            <button
              className="uk-button btn-primary uk-border-rounded"
              type="submit"
              disabled={loading}>
              Submit {loading && <div data-uk-spinner="ratio: .5"></div>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default SaveFilterModal;