import { createContext, useContext } from "react";
import AppStore from "../store/AppStore";
import AppApi from "../api/AppApi";
interface IAppContext {
  store: AppStore;
  api: AppApi;
}

export const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = (): IAppContext => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("Use App Context must be used within an App Provider");
  }
  return context;
};