import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../store/AppStore";

export const defaultUser: IUser = {
  uid: "",
  displayName: "",
  email: ""
};

export interface IUser {
  uid: string;
  displayName: string | undefined;
  email: string | undefined;
}

export class UserModel {
  private user: IUser;

  constructor(private store: AppStore, user: IUser) {
    makeAutoObservable(this);
    this.user = user;
  }

  get asJson(): IUser {
    return toJS(this.user);
  }
}