import { User } from "oidc-client-ts";

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname)
}

const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENTID,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_URL,
    scope: "openid offline_access apst_controlling",
    response_type: "code",
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    silent_redirect_uri: "https://test.ambulanzpartner.de/auth/callback",
    automaticSilentRenew: true,
    token_type: "Bearer",
    onSigninCallback,
};
export default oidcConfig;