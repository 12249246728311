import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import Store from "./Store";
import QueryModel, { IQuery } from "../model/QueryModel";

export default class QueryStore extends Store<IQuery, QueryModel> {
  Qid = window.localStorage.getItem("queryId");

  items = new Map<number, QueryModel>();
  queryId: number = Number(this.Qid);

  constructor(store: AppStore) {
    super(store);
    this.store = store;
    makeObservable(this, {
      queryId: true
    })
  }

  setQueryId(id: number) {
    runInAction(() => {
      this.queryId = id;
    })
  }

  load(items: IQuery[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new QueryModel(this.store, item))
      );
    });
  }
}
