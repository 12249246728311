import { IQuery } from "../model/QueryModel";
import AppStore from "../store/AppStore"
import AppApi from "./AppApi"

export class QueryApi {
  constructor(private api: AppApi, private store: AppStore) { }

  private apiUrl = `${process.env.REACT_APP_API_ENDPOINT}`

  async getQuery() {
    try {
      const res = await fetch(`${this.apiUrl}/rs2/controlling/queries`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })
      if (res.ok) {
        const data = await res.json();
        this.store.query.load(data as IQuery[])
      }
    } catch (error) { }
  }
}
