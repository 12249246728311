
import { IFilter } from "../model/CustomFilterModel";
import AppStore from "../store/AppStore"
import AppApi from "./AppApi"

export class FiltersApi {
  constructor(private api: AppApi, private store: AppStore) { }

  private apiUrl = `${process.env.REACT_APP_API_ENDPOINT}`

  async getAll() {
    try {
      const res = await fetch(`${this.apiUrl}/rs2/controlling/filters`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })
      if (res.ok) {
        const data = await res.json();
        this.store.filter.load(data as IFilter[]);
      }
    } catch (error) { }
  }

  async save(item: IFilter) {
    try {
      const res = await fetch(`${this.apiUrl}/rs2/controlling/filter`, {
        method: 'POST',
        mode: "cors",
        headers: this.api.auth.createHeaders(),
        body: JSON.stringify({ ...item }),
      });
      if (res.ok) {
        this.getAll()
      }
    } catch (error) { }
  }

  async delete(id: number) {
    try {
      const res = await fetch(`${this.apiUrl}/rs2/controlling/filter?id=${id}`, {
        method: 'DELETE',
        headers: this.api.auth.createHeaders(),
        mode: "cors",
      });
      if (res.ok) { }
    } catch (error) { }
  }
}