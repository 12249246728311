import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../context/Context";
import { observer } from "mobx-react-lite";

const DRAWER_ROUTES = observer(() => {

  const { t: msg } = useTranslation(['home']);
  const { store } = useAppContext()
  const queryId = store.query.queryId;

  return (
    <div className="list">
      <ul className="main uk-nav-default" data-uk-nav>
        {/* <li className="item">
          <NavLink to={`dashboard`} className="navlink">
            <span data-uk-icon="chevron-double-right" className="uk-margin-small-right"></span>
            {msg("dashboard")}
          </NavLink>
        </li> */}
        <li className="item">
          <NavLink to={`queries`} className="navlink">
            <span data-uk-icon="chevron-double-right" className="uk-margin-small-right"></span>
            {msg("queries")}
          </NavLink>
        </li>
        <li className="item">
          <NavLink to={`licenses/${queryId}`} className="navlink">
            <span data-uk-icon="chevron-double-right" className="uk-margin-small-right"></span>
            {msg("licenses")}
          </NavLink>
        </li>
      </ul>
    </div>
  );
});

const Drawer = () => {
  return (
    <div className="drawer">
      <div id="navbar-drawer" data-uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar">
          <button
            className="uk-offcanvas-close"
            type="button"
            data-uk-close
          ></button>
          <DRAWER_ROUTES />
        </div>
      </div>
      <div className="fixed-drawer uk-visible@s">
        <DRAWER_ROUTES />
      </div>
    </div>
  );
};

export default Drawer;

