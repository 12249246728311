import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../store/AppStore";

export interface IQuery {
  id: number,
  name: string,
  description: string,
  version: number
}

export const defaultQuery: IQuery = {
  id: 2,
  name: "",
  description: "",
  version: 0
}


export default class QueryModel {
  private query: IQuery;

  constructor(private store: AppStore, query: IQuery) {
    makeAutoObservable(this);
    this.query = query;
  }

  get asJson(): IQuery {
    return toJS(this.query);
  }
}