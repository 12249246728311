import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../store/AppStore";

export default class LicenseModel {
  private data: any;

  constructor(private store: AppStore, data: any) {
    makeAutoObservable(this);
    this.data = data;
  }

  get asJson(): any {
    return toJS(this.data);
  }
}